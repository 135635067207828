import React from 'react';
import { Avatar, Button, Dropdown, Layout, Menu, Result, Typography } from 'antd';
import { HomeOutlined, LogoutOutlined, RocketOutlined, UserOutlined } from '@ant-design/icons';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Home from '../Pages/Home';
import Users from '../Pages/Users';
import './Drawer.css';
import Companies from '../Pages/Companies';

export default function PersistentDrawerLeft() {
    const { Header, Content, Footer, Sider } = Layout;
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const [collapsed, onCollapse] = React.useState(false);
    const location = useLocation();
    function getDefaultKey() {
        if (location.pathname.substring(1)) {
            return location.pathname.substring(1);
        }
        return 'home';
    }
    const [defaultSelectedMenuItem, setDefaultSelectedMenuItem] = React.useState([getDefaultKey()]);
    const navigate = useNavigate();
    const handleCollapse = (c: boolean | ((prevState: boolean) => boolean)) => {
        onCollapse(c);
    };

    const menu = (
        <Menu>
            <Typography.Text strong style={{ padding: 10 }}>
                {user.attributes?.email}
            </Typography.Text>
            <Menu.Divider />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button icon={<LogoutOutlined />} type="primary" onClick={() => signOut()}>
                    Sign Out
                </Button>
            </div>
        </Menu>
    );
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider collapsible collapsed={collapsed} onCollapse={handleCollapse}>
                <div className="logo" onClick={() => navigate('/')} />
                <Menu theme="dark" mode="inline" defaultSelectedKeys={defaultSelectedMenuItem}>
                    <Menu.Item key="home" icon={<HomeOutlined />} onClick={() => navigate('/')}>
                        Home
                    </Menu.Item>
                    <Menu.Item key="users" icon={<UserOutlined />} onClick={() => navigate('/users')}>
                        Users
                    </Menu.Item>
                    <Menu.Item key="companies" icon={<RocketOutlined />} onClick={() => navigate('/companies')}>
                        Companies
                    </Menu.Item>
                </Menu>
                <div style={{ width: '100%', height: '80vh' }} onClick={() => onCollapse((c) => !c)} />
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{ padding: 0 }}>
                    <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
                        <Avatar style={{ float: 'right', color: 'white', backgroundColor: '#1890ff', margin: 14, cursor: 'pointer' }}>
                            {user.attributes?.email.charAt(0).toUpperCase()}
                        </Avatar>
                    </Dropdown>
                </Header>
                <Content style={{ margin: '12px 16px' }}>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/companies" element={<Companies />} />
                        <Route
                            path="*"
                            element={
                                <Result
                                    status="404"
                                    title="404"
                                    subTitle="Sorry, the page you visited does not exist."
                                    extra={
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                setDefaultSelectedMenuItem((s) => {
                                                    s.pop();
                                                    s.push('home');
                                                    return s;
                                                });
                                                navigate('/');
                                            }}
                                        >
                                            Back Home
                                        </Button>
                                    }
                                />
                            }
                        />
                    </Routes>
                </Content>
                <Footer style={{ textAlign: 'center' }}>2022 ByteChek, Inc. All Rights Reserved.</Footer>
            </Layout>
        </Layout>
    );
}
